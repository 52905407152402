<template lang="pug">
NuxtLayout(
    name="default"
)
    .mainbody_neutral
        main.mainbox_center.gap-4
            .flex.flex-1.justify-center.items-center
                .flex.flex-row.gap-4.space-y-4
                    .flex.justify-center
                        BaseLogo
                    .flex.flex-1.justify-center
                        .w-full.space-y-1
                            .text-lg.font-bold 404
                            .w-full.text-sm Halaman ini tidak ditemukan.

    ColorMode
</template>
<script setup lang="ts">
definePageMeta({
    layout: false,
})

useHead({
    title: 'Register | Dashboard',
})

const toast = useToast()
const router = useRouter()
</script>
