import { navigationMenu } from './config/NavigationMenu'
import { table } from './config/Table'
import { button } from './config/Button'
import { modal } from './config/Modal'
import { card } from './config/Card'
import { dropdownMenu } from './config/DropdownMenu'
import { avatar } from './config/Avatar'
import { inputMenu } from './config/InputMenu'

export default defineAppConfig({
    ui: {
        colors: {
            primary: 'indigo',
            secondary: 'blue',
            success: 'green',
            info: 'cyan',
            warning: 'yellow',
            error: 'red',
            neutral: 'neutral',
        },
        theme: {
            colors: [
                'white',
                'primary',
                'secondary',
                'success',
                'warning',
                'error',
                'neutral',
                'zinc',
                'tertiary',
                'info',
            ],
        },
        inputMenu,
        avatar,
        dropdownMenu,
        card,
        modal,
        navigationMenu,
        table,
        button,
    },
})
