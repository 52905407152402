
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as forgot_45passwordsIDU4y9pFzMeta } from "/home/dashboard:client/pages/forgot-password.vue?macro=true";
import { default as indexen6wFEjmSDMeta } from "/home/dashboard:client/pages/index.vue?macro=true";
import { default as loginH12dIk1Q2XMeta } from "/home/dashboard:client/pages/login.vue?macro=true";
import { default as logoutqXXqB5Gj2LMeta } from "/home/dashboard:client/pages/logout.vue?macro=true";
import { default as indexHTahXZNyYYMeta } from "/home/dashboard:client/pages/register/index.vue?macro=true";
import { default as step_452kJAxqy9KYSMeta } from "/home/dashboard:client/pages/register/step-2.vue?macro=true";
import { default as store6TklhkoA2CMeta } from "/home/dashboard:client/pages/register/store.ts?macro=true";
import { default as _91code_93I9myOWi3jeMeta } from "/home/dashboard:client/pages/reset-password/[code].vue?macro=true";
import { default as index8y2nX3pzrIMeta } from "/home/dashboard:client/pages/reset-password/index.vue?macro=true";
import { default as indexNRe2aIpEzDMeta } from "/home/dashboard:client/pages/template/mystore/index.vue?macro=true";
import { default as creategipMu9MH0cMeta } from "/home/dashboard:client/pages/template/salesorder/create.vue?macro=true";
import { default as indexcAPQZGbzjiMeta } from "/home/dashboard:client/pages/template/salesorder/index.vue?macro=true";
import { default as indexYMDiRKbDWBMeta } from "/home/dashboard:client/pages/template/settings/index.vue?macro=true";
export default [
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordsIDU4y9pFzMeta || {},
    component: () => import("/home/dashboard:client/pages/forgot-password.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexen6wFEjmSDMeta || {},
    component: () => import("/home/dashboard:client/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginH12dIk1Q2XMeta || {},
    component: () => import("/home/dashboard:client/pages/login.vue")
  },
  {
    name: "logout",
    path: "/logout",
    meta: logoutqXXqB5Gj2LMeta || {},
    component: () => import("/home/dashboard:client/pages/logout.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: indexHTahXZNyYYMeta || {},
    component: () => import("/home/dashboard:client/pages/register/index.vue")
  },
  {
    name: "register-step-2",
    path: "/register/step-2",
    meta: step_452kJAxqy9KYSMeta || {},
    component: () => import("/home/dashboard:client/pages/register/step-2.vue")
  },
  {
    name: "register-store",
    path: "/register/store",
    component: () => import("/home/dashboard:client/pages/register/store.ts")
  },
  {
    name: "reset-password-code",
    path: "/reset-password/:code()",
    meta: _91code_93I9myOWi3jeMeta || {},
    component: () => import("/home/dashboard:client/pages/reset-password/[code].vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: index8y2nX3pzrIMeta || {},
    component: () => import("/home/dashboard:client/pages/reset-password/index.vue")
  },
  {
    name: "template-mystore",
    path: "/template/mystore",
    meta: indexNRe2aIpEzDMeta || {},
    component: () => import("/home/dashboard:client/pages/template/mystore/index.vue")
  },
  {
    name: "template-salesorder-create",
    path: "/template/salesorder/create",
    meta: creategipMu9MH0cMeta || {},
    component: () => import("/home/dashboard:client/pages/template/salesorder/create.vue")
  },
  {
    name: "template-salesorder",
    path: "/template/salesorder",
    meta: indexcAPQZGbzjiMeta || {},
    component: () => import("/home/dashboard:client/pages/template/salesorder/index.vue")
  },
  {
    name: "template-settings",
    path: "/template/settings",
    meta: indexYMDiRKbDWBMeta || {},
    component: () => import("/home/dashboard:client/pages/template/settings/index.vue")
  }
]